import { request } from './Request';
import { config } from '../config';
import { CreateOrganizationRequest, CreateBookingRequest, SpacesRequest, ArrangementsRequest } from './types';
import { Arrangement, Catering, Equipment, Space, Invoice, Accomodation, Availability } from '../../types';

export class HttpService {
	arrangements(options: ArrangementsRequest): Promise<Arrangement[]> {
		return request(`venues/${config.venueId}/arrangements`, { params: options });
	}

	catering(options: Record<string, string>): Promise<Catering[]> {
		return request(`venues/${config.venueId}/catering`, { params: options });
	}

	equipment(options: Record<string, string>): Promise<Equipment[]> {
		return request(`venues/${config.venueId}/equipment`, { params: options });
	}

	accomodations(options: Record<string, string>): Promise<Accomodation[]> {
		return request(`venues/${config.venueId}/accomodations`, { params: options });
	}

	space(spaceId: number, options: SpacesRequest): Promise<Space> {
		return request(`venues/${config.venueId}/spaces/${spaceId}`, { params: options });
	}

	async spaces(options: SpacesRequest): Promise<Space[]> {
		const response = await request(`venues/${config.venueId}/spaces`, { params: options });

		return response.map((space: any) => {
			space.category.split(',');
			return space;
		});
	}

	private createOrganization(body: CreateOrganizationRequest) {
		return request(`organizations/widget`, { body, method: 'POST' });
	}

	async createBooking(organizationBody: CreateOrganizationRequest, bookingBody: CreateBookingRequest): Promise<void> {
		// Create a user, this is needed for the back-end
		await this.createOrganization(organizationBody);

		await request('bookings/widget', {
			method: 'POST',
			body: bookingBody,
			headers: { Authorization: `Basic ${btoa(organizationBody.username)}:` },
		});
	}

	async invoice(body: CreateBookingRequest): Promise<Invoice> {
		return request('invoice', { method: 'POST', body });
	}

	async venueAvailability(date: string, view: 'month' = 'month'): Promise<Availability[]> {
		return request(`venue/${config.venueId}/availability/calendar`, { params: { date, view } });
	}

	async spaceAvailability(spaceId: number, date: string, view: 'month' = 'month'): Promise<Availability[]> {
		return request(`spaces/${spaceId}/availability/calendar`, { params: { date, view } });
	}
}
