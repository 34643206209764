import { Dayjs } from 'dayjs';

export enum Screen {
	Filters = 'filters',
	Overview = 'overview',
	Details = 'details',
	Book = 'book',
	Success = 'success',
	Error = 'error',
}

export enum SpaceCategory {
	Meeting = 'meeting',
	Training = 'training',
	Workshop = 'workshop',
	Dinner = 'dinner',
	Reception = 'reception',
	Other = 'other',
}

export enum CapacityCategory {
	Standing = 'standing',
	Cabaret = 'cabaret',
	Carre = 'carre',
	Circle = 'circle',
	Exam = 'exam',
	School = 'school',
	Theater = 'theater',
	'U-Shape' = 'u-shape',
	Party = 'party',
	Dinner = 'dinner',
	Boardroom = 'boardroom',
}

export interface Filters {
	persons: number;
	type: SpaceCategory;
	startDate: Dayjs;
	endDate: Dayjs;
	readonly duration: number;
}

export enum Period {
	DayPart = 'daypart',
	Day = 'day',
	Hour = 'hour',
	Package = 'package',
}

export type Category = Arrangement | Catering | Equipment;

export interface Content {
	arrangements?: Arrangement[];
	catering?: Catering[];
	equipment?: Equipment[];
}

export interface Option {
	label: string;
	is_selected: boolean;
	description?: string;
}

export enum PriceUnit {
	PerPerson = 'perUnit',
	PerDayPart = 'perDaypart',
	PerHourPerPerson = 'perHour',
	Total = 'Total',
}

export interface Pricing {
	excluded: number;
	included: number;
	unit: PriceUnit;
	tax_low?: number;
	tax_high?: number;
	tax_breakdown: {
		high: number;
		low: number;
	};
}

export interface Price {
	amount: number;
	unit: PriceUnit;
	isExtra?: boolean;
}

export interface Arrangement {
	id: number;
	title: string;
	type: string;
	description?: string | null;
	category: string;
	component: string;
	pricing: Pricing;
	price: number;
	price_tax_breakdown: {
		high: number | null;
		low: number | null;
		none?: number | null;
	} | null;
	discounts: {
		minimum_guests?: number;
		guests?: number;
		percentage?: number;
	}[];
	spaces: number[];
	images?: string[] | null;
	duration: number;
	products: {
		catering: {
			id: number;
			title: string;
			description?: string | null;
			category: string;
			component?: string;
			price?: { day: string } | { day: string }[];
			tax_percentage?: number;
			quantity?: number | null;
			isSelected: boolean;
			images?: string[] | null;
		}[];
		equipment: {
			id: number;
			title: string;
			images: string[];
			category: string;
			component?: string;
			price: number;
			tax_percentage: number;
			quantity: number | null;
			is_selected: boolean;
		}[];
	};
	activated: boolean | number;
	space_always_included: boolean | number;
	quantity?: number | null;
	quote?: any;
}

export interface Catering {
	id: number;
	title: string;
	category: string;
	component: string;
	gradation?: string | null;
	description: string | null;
	images?: string[] | null;
	pricing: Pricing;
	activated: number;
}

export interface Equipment {
	id: number;
	title: string;
	category?: string | null;
	component?: string | null;
	description?: string | null;
	activated?: number | null;
	pricing?: Pricing | null;
	quantity?: number | null;
}

export interface PricePerDayPart {
	morning: number;
	afternoon: number;
	evening: number;
	mor_aft: number;
	aft_eve: number;
	mor_aft_eve: number;
}

export interface Space {
	id: number;
	name: string;
	description: string;
	quantity: number;
	surface: number;
	pricing: {
		/**
		 * .parts can be an empty array in case the space
		 * can only be booked in combination with an
		 * arrangement
		 */
		parts?: PricePerDayPart;
		tax_rate: number;
	};
	images: string[];
	facilities: any[];
	category: string;
	capacity: {
		max: number;
		min: number;
	};
	from_price: {
		price: number;
		total_price: number;
		type: Period;
	};
	equipment: Equipment[];
	venue: {
		id: number;
		name: string;
	};
	setups: SpaceSetup[];
	activated: number;
	quote?: any;
}

export interface Invoice {
	summary: {
		base_price: number;
		discount: number;
		tax: {
			low: number;
			high: number;
			distribution: {
				low: number;
				high: number;
			};
		};
		total_price: number;
		price: number;
		total_price_per_person: number;
		base_price_per_person: number;
	};
	details: {
		category: string;
		description: string;
		quantity: number;
		price: number;
		total_price: number;
		tax_percentage: number;
		on_payment: boolean;
		on_invoice: boolean;
	}[];
}

export interface Accomodation {
	id: number;
	title: string;
	description: string;
	category: string;
	component: string;
	pricing: Pricing;
	discounts?: {
		minimum_guests?: number;
		guests?: number;
		percentage?: number;
	}[];
	images?: string[];
	activated: number;
}

export interface SelectedAccomodation extends Accomodation {
	quantity: number;
}

export interface DropdownOptions {
	label: string;
	value: string;
}

export interface SpaceSetup {
	label: CapacityCategory;
	min: number;
	max: number;
}

export interface Availability {
	date: string;
	partial: boolean;
	open: boolean;
	availability_hours: string[];
	availability: [{ morning: 1 | 0 }, { afternoon: 1 | 0 }, { evening: 1 | 0 }];
}
